<div class="fixed-mic " id="movableCard-main">
  <div class="bottomright" id="settingId" style="z-index: 99999999;">
    <div class="commonstyle" id="tourSetting">
      <span class="setttingMain">
        <span class="ToolSetting">Setting</span>
        <img class="setting" #popup id="settingId1" (click)="dropSetting()" src="../../assets/newsetting.png">
      </span>
    </div>
  </div>


  <!-- // avatar hide button feature  -->
  <div class="bottomChat " id="mobileAvatarButton">
    <div class="commonstyle" onkeyup="event.preventDefault()" (click)="hideMethod()">
      <div class="">
        <span class="tooltipAvatar">Hide Hannah</span>
        <span class="tooltipAvatar" *ngIf="mobileAvatarOnOff">Show Hannah</span>
        <span>
          <img class="" *ngIf="!mobileAvatarOnOff" class="avatar_icon chat_icon"
            src="../../assets/Close_Interaction.png">

          <img class="" *ngIf="mobileAvatarOnOff" class="avatar_icon imgSet chat_icon"
            src="../../assets/Show_Hannah.png">

        </span>
      </div>
    </div>
  </div>


  <!-- // chat input box  -->
  <div class="centerDiv" id="centerDiv">
    <div class="" id="chat-bar" style="z-index: 1100000;">

    </div>
  </div>

  <div class="speaking-lady   imgTest" id="movableCard" style="border-radius: 0px;"
    [style.background-color]="backgroundColor">
    <div id="avatarLoaders" style="margin-top:50px">
      <div id="loader" style="display: flex;justify-content: center; font-size: 18px;">
        <img src="../../assets/images/Rolling.gif" style="width: 50px; height:50px ">
      </div>
      <p style="display: flex;justify-content: center; font-size: 16px; color: white;">
        {{avatarName}} is on her way to meet with you.<br> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; Please wait a
        moment.
      </p>
    </div>


    <ng-container *ngIf="!messageForQueueAvatar">
      <!-- speakingsss_large   // its use when the card open in full screen on open -->
      <div class="speakingsss " id="cross" style="position: absolute;" (click)="onLoadCard('sm-video')">
        <span id="maximizeAvatar">
          <svg width="23" height="23" fill="#FFFFFF" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 3h6v6"></path>
            <path d="M9 21H3v-6"></path>
            <path d="m21 3-7 7"></path>
            <path d="m3 21 7-7"></path>
          </svg>
          <span style="font-style: normal;" class="max">Maximize</span>
        </span>


        <span style="margin-top: -10px;" style="position: absolute;" id="minimizeAvatar">
          <!-- <img src="../../../../assets/interaction/x-circle.svg" style="width: 29px; height: 29px;"> -->
          <img id="cross1"
            [src]="isMobileNormalTrue ? '../../assets/interaction/arrow-left.svg' : '../../assets/interaction/x-circle.svg'"
            style="width: 30px; height: 30px;">
          <span class="min" style="font-style: normal;">Minimize</span>
        </span>
      </div>

    </ng-container>

    <div id="iconShow" class="zz showI"
      style=" justify-content: left; margin-left: 18px; margin-top: 10px; cursor: pointer">

    </div>

    <div class="rightDiv zz">

      <!-- height: 30px; -->
      <div id="hambergerBar" class="notSchool" style="height: 45px;"></div>

    </div>


    <!-- // hand stop avatar voice  -->
    <!-- <div class="div" id="stopTourGuide"> -->
    <div class="stopavatar showI" id="stopavatarId">
      <div class="bottomStopSpeaking pausebtn" id="stopspeakingID" style="z-index: 1400000;"
        [style.bottom]="stopBottomSize" [style.left]="stopLeftSize">
        <!-- <span class="stop">Stop the AI Being while speaking</span> -->
        <img src="../../assets/interaction/stopspeaking.svg" *ngIf="!unmuteMicrophone" onkeyup="event.preventDefault()"
          (click)="stopDigitalPerson()" class="stopIcon">
      </div>

    </div>


    <!-- // hand unmute avatar voice  -->
    <div class="stopavatar" *ngIf="unmuteMicrophone">
      <div class="bottomStopSpeaking pausebtn" style="z-index: 1400000;" [style.bottom]="stopBottomSize"
        [style.left]="stopLeftSize">
        <img src="../../assets/interaction/unmuteSpeaking.png" onkeyup="event.preventDefault()"
          (click)="muteDigitalPerson()" class="stopIcon mt-2">
      </div>
    </div>



    <!-- // queue message for avatar  -->
    <div *ngIf='messageForQueueAvatar' style="margin-top:50px">
      <div id="loader" style="display: flex;justify-content: center; font-size: 18px;">
        <img src="../../assets/images/Rolling.gif" style="width: 50px; height:50px ">
      </div>
      <p style="display: flex;justify-content: center; font-size: 16px; color: white;">
        Sorry, I am busy in serving others.<br> Give me a moment I will be right back.
      </p>
    </div>



    <!-- // avatar video card -->
    <div class="userVideo">
      <div class="g" style="display: flex; justify-content: center;">
        <!-- <div class="uneeqAvatar" id="sm-video">  [muted]="videoMuted" [autoplay]="autoplayTue"-->
        <div class="uneeqAvatar" id="sm-video">
          <video id="smVideo" width="100%" height="100%" autoplay playsinline></video>
        </div>
        <!-- <video src="../../assets/images/mov_bbb.mp4" preload="auto" autoplay muted style=" width: 100%; height: 100%;"></video> -->

      </div>
    </div>


    <div class="peerVideo">
      <div class="QuestionCard fontss hideMessage" id="textDisplay" style="margin-top: 21px;">
        <div class="avatarspeak-s fontss" id="descpText">
        </div>
      </div>

      <div class="playerOne  hideMessage fontss" id="ImageDisplay" style="margin-top: 21px;">
        <div class="" style="height:330px;">
          <img class="ImageAspectRatio Qimage" [src]="ImageData"
            style=" box-shadow: rgba(239, 221, 221, 0.35) 0px 5px 15px; ">
          <!-- Want to work -->
          <img class="ImageAspectRatio " src="../../assets/nb.jpg"
            style="height: 330px; max-width: 480px;  box-shadow: rgba(239, 221, 221, 0.35) 0px 5px 15px; ">
          <!-- Finding -->
        </div>
      </div>


      <ng-container *ngIf="DescAnswer != ''">
        <div class="chatbubbleBox   hideMessage" id="message" style="margin-top: 8px;">

          <div #messageContainer class="chat-container">
            <div class="message-container" *ngFor="let chat of storedChatBubbleMessage">
              <ng-container *ngIf="chat.source === 'user'">
                <span class="receiver-timer">{{chat.time}}</span>
                <div class="message  receiver-message">
                  <span class="receviedSpan scrollbar"> {{chat.text}} </span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" style="align-self: flex-end;
                z-index: 100;
                margin-right: 6px;
                margin-top: -25px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M8 0.5L17 17.5L0.5 12.5L8 0.5Z" fill="#2A7CC7" />
                </svg>
              </ng-container>



              <ng-container *ngIf="chat.source === 'persona'">

                <span class="sender-timer">{{chat.time}}</span>

                <div class="message scrollbar sender-message">
                  <span class="senderSpan">{{chat.text}} </span>

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" style="    z-index: 100;
                margin-top: -25px;
                margin-left: 6px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M9 0.5L0 17.5L16.5 12.5L9 0.5Z" fill="white" />
                </svg>
              </ng-container>

            </div>

            <ng-container *ngIf="showLoaderMess">
              <div class="loader"> <img src="../../assets/threedot.gif" style="width: 50px;
                height: 50px;margin-left: 10px; border-radius: 15px;
                background-color: white;"></div>
              <svg xmlns="http://www.w3.org/2000/svg" style="     z-index: 100;
margin-top: -45px;
margin-left: 7px;" width="17" height="18" viewBox="0 0 17 18" fill="none">
                <path d="M9 0.5L0 17.5L16.5 12.5L9 0.5Z" fill="white" />
              </svg>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- -->



      <!-- <div class="hideMessage" id="userCC">
        <div class="bottomboxSize">
          <div class="container">
            <div class=" d-flex justify-content-center">
              <div class="ccBoxNew " id="userText">

                <div class="avatarspeak-s" id="local-transcript">
                  You: {{userInputText}}
            
                </div>

              </div>
            </div>
          </div>
        </div>

      </div> -->

      <!-- -- setting--- -->
      <div class="dropBoxNew showI" id="dropDownSetting">

        <div class="d"
          style="width: 310px; background-color: black; color: white;border-radius: 8px ; margin-left: 50px; margin-top: 40px;">

          <button class="accordions" (click)="clickFunction('panel1')" #popup2>
            <div class="row">
              <img alt="" style="width: 20px; border-radius: 0px;" class="ml-3" src="../../assets/interaction/cc.svg" />
              &nbsp;
              <span class="settingHeading">Captions</span>
            </div> <span style="display: flex; justify-content: right; margin-top: -25px;"><img style="width: 20px;"
                alt="" src="../../assets/interaction/chevron-right.svg" /></span>
          </button>
          <div class="panels " id="panel1">

            <div class="oneLine">

              <label>
                <input (click)="avatarCCOnOf()" type="checkbox" name="" id="" class="check1" [(ngModel)]="UserccOnOff">
                <span></span>
              </label>
              <!-- <p>User CC</p> -->
              <p class="commonBox">Chat CC</p>
            </div>

          </div>


          <div
            style="width: 80%; height: 0.5px; border: 0.5px white solid ; margin-left: 18px; margin-right: 10px;margin-top: 5px;">
          </div>

          <button class="accordions" (click)="StartTourGuideSetting()" #popup2>
            <div class="row">
              <img style="width: 20px;" class="ml-3" src="../../assets/interaction/map-02.svg" /> &nbsp;
              <span class="settingHeading">Tour Guide</span>
            </div>
            <span style="display: flex; justify-content: right; margin-top: -25px;"><img style="width: 20px;" alt=""
                src="../../assets/interaction/chevron-right.svg" /></span>
          </button>

        </div>



      </div>

      <!-- -- chat box , mic ------ -->
      <div class="bottomboxSize baseChatBottom" id="bottomBar"   [style.bottom.px]="bottomPosition" [style.height]="bottomPositionheight" [style.width]="bottomPositionWidth">
        <div class="container">
          <div class="d-flex justify-content-center centerDiv">
            <div class="box-1" id="boxForInput" style="z-index: 21000000">


              <div class="input-container">
                <input type="text" [style.width]="inputWidthSize" id="textTourBox" [style.margin-top]="inputmarginTop"
                  [style.height]="inputheightSize" class="group-item"
                  onkeydown="if (event.keyCode == 13) document.getElementById('sendId1').click()" [(ngModel)]="userText"
                  placeholder="Type here or push mic to talk"
                  style="font-size: medium !important; padding-left: 26px !important;">

                <img class="chat-button" id="sendId1" style="width: 50px;height: 45px; background-color: white;"
                  (click)="sendTextToAvatar()" src="../../assets/send-03.svg">
              </div>



            </div>


            <div class="box-2">
              <div class="di ">
                <!-- <span class="mic22">Microphone</span> -->
                <span>

                  <img alt="" id="voiceId" *ngIf="isvoiceAnimationOn == false"
                    (click)="disableMicButton ? null : onclickMic()" [style.width.px]="micWidth"
                    [style.height.px]="micHeight" [style.margin-left]="micMarginLeft2" src="../../assets/mute.png" />

                  <img alt="" *ngIf="isvoiceAnimationOn == true" src="../../assets/micNewOrange.png"
                    [style.width.px]="micWidthOnly" [style.margin-left]="micMarginLeft2" (click)="stopOnClick()"
                    [style.height.px]="micHeight" [style.margin-left.px]="micMarginleft">
                </span>

              </div>

            </div>
            
            <div class="box-3" id="box3Main" style="margin-left: 10px; cursor: pointer;">
              <!-- <span class="dotTooltip">Indicator</span> -->
              <img *ngIf="!dotIndicatorAnimation" style="    position: relative; 
          margin-left: 3px;
          margin-top: 10px;" src="../../assets/dotIndicator.svg">
              <div id="bars" style="margin-top: 15px;" *ngIf="dotIndicatorAnimation">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>


              </div>
           

             
            </div>
          </div>
        </div>

      </div>




    </div>

  </div>


</div>