import {Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  whiteLogo= "https://edyouwebsite.s3.us-west-2.amazonaws.com/edyou-logo-horiz-empower-white.png";
  colorLogo = "https://edyouwebsite.s3.us-west-2.amazonaws.com/edyou-logo-horiz-empower-color.png";
  container = ".global-nav-container";

  ngOnInit(): void {
    document.addEventListener('keydown', this.preventSpaceScroll);
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.preventSpaceScroll);
  }

  preventSpaceScroll(event: KeyboardEvent): void {
    const target = event.target as HTMLElement;
        const isTargetSpecificInput = target.id === 'textTourBox';
    if (event.code === 'Space' || event.key === ' ') {
      if (!isTargetSpecificInput) {
        event.preventDefault();
      }
    }
  }

}